.alertify {
  position: fixed;
  background-color: rgba(0,0,0,0.3);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.alertify.hide {
  opacity: 0;
  pointer-events: none;
}
.alertify,
.alertify.show {
  box-sizing: border-box;
  transition: all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.alertify,
.alertify * {
  box-sizing: border-box;
}
.alertify .dialog {
  padding: 12px;
}
.alertify .dialog,
.alertify .alert {
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.alertify .dialog > *,
.alertify .alert > * {
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.14), 0 4px 5px 0 rgba(0,0,0,0.098), 0 1px 10px 0 rgba(0,0,0,0.084);
}
.alertify .dialog .msg,
.alertify .alert .msg {
  padding: 12px;
  margin-bottom: 12px;
  margin: 0;
  text-align: left;
}
.alertify .dialog input:not(.form-control),
.alertify .alert input:not(.form-control) {
  margin-bottom: 15px;
  width: 100%;
  font-size: 100%;
  padding: 12px;
}
.alertify .dialog input:not(.form-control):focus,
.alertify .alert input:not(.form-control):focus {
  outline-offset: -2px;
}
.alertify .dialog nav,
.alertify .alert nav {
  text-align: right;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button),
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
  background: transparent;
  box-sizing: border-box;
  color: rgba(0,0,0,0.87);
  position: relative;
  outline: 0;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover,
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover,
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active,
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active {
  background-color: rgba(0,0,0,0.05);
}
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus,
.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus {
  border: 1px solid rgba(0,0,0,0.1);
}
.alertify .dialog nav button.btn,
.alertify .alert nav button.btn {
  margin: 6px 4px;
}
.alertify-logs {
  position: fixed;
  z-index: 100;
}
.alertify-logs.bottom,
.alertify-logs:not(.top) {
  bottom: 16px;
}
.alertify-logs.left,
.alertify-logs:not(.right) {
  left: 16px;
}
.alertify-logs.left .alertify-log,
.alertify-logs:not(.right) .alertify-log {
  float: left;
}
.alertify-logs.right {
  right: 16px;
}
.alertify-logs.right .alertify-log {
  float: right;
  transform: translateZ(0);
  right: 0;
}
.alertify-logs.right .alertify-log.alertify-log-enter {
  right: -110%;
}
.alertify-logs.right .alertify-log.alertify-log-enter-active {
  right: 0;
}
.alertify-logs.right .alertify-log.alertify-log-exit {
  right: -110%;
}
.alertify-logs.right .alertify-log.alertify-log-enter-active {
  right: 0;
}
.alertify-logs.top {
  top: 0;
}
.alertify-logs .alertify-log {
  padding: 12px;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
  border-radius: 1px;
  box-sizing: border-box;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  clear: both;
  backface-visibility: hidden;
  perspective: 1000;
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}
.alertify-logs .alertify-log,
.alertify-logs .alertify-log.default {
  background: rgba(0,0,0,0.8);
}
.alertify-logs .alertify-log.error {
  background: rgba(219,102,104,0.8);
}
.alertify-logs .alertify-log.success {
  background: rgba(72,189,31,0.9);
}
.alertify-logs .alertify-log.alertify-log-enter {
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.alertify-logs .alertify-log.alertify-log-enter-active {
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}
.alertify-logs .alertify-log.alertify-log-exit {
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto;
}
.alertify-logs .alertify-log.alertify-log-exit-active {
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.notification-banners {
  position: absolute;
  width: 100%;
}
.notification-banner {
  background-color: #db6668;
  color: #fff;
}
.notification {
  width: 100%;
  display: flex;
}
.notification span {
  flex: 1 0 auto;
}
.notification button {
  flex: 0 0 auto;
  background-color: transparent;
  color: #fff;
}
.input-list button[class*="large___"] {
  padding: 8px 12px;
  font-size: 0.8em;
  line-height: 1em;
}
.input-list .input-list-add-button {
  margin-bottom: 10px;
}
.input-list .input-list-item {
  display: flex;
}
.input-list .input-list-item button {
  display: flex;
  align-items: center;
  color: #5197d6;
  background-color: transparent;
  border: none;
}
.input-list .input-list-item label[class*="label___"] {
  margin-top: 8px;
}
span[class*="toggle___"] {
  margin-bottom: 10px;
}
.api-edit-form p {
  font-size: 14px;
}
.home-panel {
  display: grid;
  grid-template-areas: 'step body icon';
  grid-template-columns: minmax(auto, 125px) 1fr minmax(auto, 125px);
  grid-template-rows: auto;
  color: #5197d6;
  align-items: center;
  justify-items: center;
}
.home-panel .home-grid-step {
  grid-area: step;
  font-size: 12rem;
  font-weight: bold;
  text-align: center;
}
.home-panel .home-grid-body {
  grid-area: body;
}
.home-panel .home-grid-title {
  font-size: 2rem;
}
.home-panel .home-grid-icon {
  grid-area: icon;
}
.App {
  text-align: center;
}
#root * {
  box-sizing: border-box;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}
.App-link {
  color: #61dafb;
}
p {
  margin: 0px auto;
  font-size: 18px;
  line-height: 24px;
}
@-moz-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
